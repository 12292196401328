// https://gist.github.com/lovasoa/11357947
export const byteLength = (str) => {
  // returns the byte length of an utf8 string
  var s = str.length
  for (var i=str.length-1; i>=0; i--) {
    var code = str.charCodeAt(i)
    if (code > 0x7f && code <= 0x7ff) {
      s++
    } else if (code > 0x7ff && code <= 0xffff) {
      s+=2
    }
    if (code >= 0xDC00 && code <= 0xDFFF) {
      //trail surrogate
      i--
    }
  }
  return s
}
