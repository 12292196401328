<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          small
          label
          :color="formattedItem.colorLengthInKb"
        >
          {{ formattedItem.lengthInKb|decimal(1) }} Kb
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon>
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-icon>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { byteLength } from '@/utils/strings'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = item.sync.method
      item.subtitle = this.$options.filters.shortDateTime(item.sync.timestamp)
      item.lengthInKb = byteLength(JSON.stringify(item.sync.params)) / 1024
      // menos de 4Mb
      if (item.lengthInKb < 4096) {
        item.colorLengthInKb = 'success'
      } else {
        item.colorLengthInKb = 'warning'
      }
      return item
    },
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    },
  },
}
</script>
